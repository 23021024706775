.title {
    font-weight: bold !important;
    margin-bottom: 30px !important;
}

.titleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    text-align: center;
}

.studyHoursContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
}

.studyHoursBar {
    width: 100%;
    margin: 10px 0px;
    text-align: center;
}

.guestListContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
}

.guestListSingleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.nameAndGuestsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}

.guestsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.membersContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
}

.membersInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    width: 100%;
    padding: 10px;
}

.announcementsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
}

.singleAnnouncementContainer {
    display: flex;
}

@media (max-width: 600px) {
    .guestListTitle {
        font-size: 1rem !important;
    }
    .guestsContainer {
        display: none;
    }
}