.screenContainer {
    height: calc(100vh - 162px);
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listContainer {
    padding: 5%;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24) !important;
}

.list {
    display: flex;
    flex-wrap:wrap;
    width: 100%;
    gap: 10px;
    overflow-y: auto;
}

.name {
    font-size: 2rem;
    font-weight: 600;
    margin: 1%;
    margin-left: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1%;
    border-bottom: solid 1px #505050;
}

.search {
    width: 75% !important;
    padding: 1%;
}

.icon {
    font-size: 2rem;
    cursor: pointer;
    margin: 10px;
}

.settingsIcon {
    padding-left: 0px !important;
}