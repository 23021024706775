.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .signup-wrapper {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .signup-input {
    margin-bottom: 10px !important;
  }
  
  .signup-logo {
    height: 125;
    width: 131;
    margin-bottom: 50px;
  }
  
  .signup-image {
    width: 40%;
  }
  
  
  /* MEDIA QUERIES */
  
  
  @media (max-width: 1450px) {
    .signup-image {
      height: 100%;
      width: auto;
    }
  }
  
  @media (max-width: 1050px) {
    .signup-image {
      display: none;
    }
  }
  
  @media (max-width: 850px) {
    .signup-wrapper {
      width: 100% !important;
    }
  }