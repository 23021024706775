.createdAt {
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: center;
    border-right: 2px solid #505050;
    padding-right: 20px;
    max-width: 70px;
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 500px;
}

.message {
    text-overflow: ellipsis;
    overflow: clip;
    white-space: pre;
    max-width: 500px;
}

@media (max-width: 1050px) {
    .message {
        display: none;
    }
}

@media (max-width: 940px) {
    .title {
        max-width: 300px;
        font-size: 1.2rem;
    }
}