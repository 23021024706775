.listTitle {
    display: flex;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.cellContainer {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
}

.display {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    background-color: #F0F0F0;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.active {
    background-color: #8BFF6F;
}

.inactive {
    background-color: #FF6D6D;
}

.visible {
    background-color: #44FFFF;
}

.icon {
    font-size: 2rem;
    margin-left: 10px;
}

.itemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media (max-width: 600px) {
    .listTitle {
        font-size: 1.2rem !important;
    }
}