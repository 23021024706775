.createInviteButtonContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25px;
    right: 25px;
}

.organizationMembersScreen {
    height: calc(100vh - 120px);
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.title {
    font-weight: bold !important;
    margin-bottom: 20px !important;
}