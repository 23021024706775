.icon {
    margin-right: 10px;
}

.iconCollapsed {
    margin-right: 0px;
}

.listItemContainer {
    margin: 8px 15px;
    border-radius: 10px;
    display: flex;
}

.organizations {
    margin: 8px;
}

.listItem {
    display: flex;
}

.listItemCollapsed {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dropdown {
    display: none;
    flex-direction: column;
    position: fixed;
    z-index: 1000;
    top: 133px;
    left: 8px;
    border: 1px;
    border-radius: 3px;
    width: 208px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
}

.organization {
    border: 1px solid gray;
    border-radius: 3px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 10px;
    margin-bottom: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.organizationCollapsed {
    border: 1px solid gray;
    border-radius: 3px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 10px;
    margin-bottom: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.organizationChoices {
    padding: 3px !important;
    cursor: pointer;
}

.button {
    display: flex;
    padding: 10px 5px !important;
}