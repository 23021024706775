.container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline !important;
    text-align: left !important;
    border-radius: 10px;
    margin: 10px;
    flex: 1 1 content;
    box-sizing: border-box;
    white-space: nowrap;
}

.memberName {
    font-weight: bold !important;
    text-align: left !important;
    font-size: 1.25rem !important;
}

.singleGuestContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5px !important;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin: 10px 10px 10px 0px;
}

.guestsContainer {
    display: flex;
    flex-wrap: wrap;
}