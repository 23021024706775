.nameAndAvatar {
    display: flex !important;
    align-items: center !important;
    white-space: nowrap !important;
}

.nameContainer {
    white-space: nowrap;
    margin-left: 30px;
    text-align: center;
}

.row {
    overflow-x: hidden !important;
}

.pointInput {
    width: 45px;
}

@media (max-width: 600px) {  
    .categoryTitle {
        font-size: 1.25rem !important;
    }
}