.createButtonContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25px;
    right: 25px;
}

.title {
    display: flex;
    flex-wrap: nowrap;
    font-weight: bold !important;
    margin-bottom: 30px !important;
}

.guestListsScreen {
    height: calc(100vh - 110px);
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

@media (max-width: 600px) {
    .title {
        margin: 0px !important;
        font-size: 2.5rem !important;
    }
}