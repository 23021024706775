.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.login-wrapper {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-input {
  margin-bottom: 10px !important;
}

.forgot-password-grid {
  text-align: left;
}
.forgot-password {
  color: #505050;
  font-weight: bold;
}

.forgot-password:hover {
  cursor: pointer;
}

.login-logo {
  height: 125;
  width: 131;
  margin-bottom: 50px;
}

.login-image {
  width: 40%;
}


/* MEDIA QUERIES */


@media (max-width: 1450px) {
  .login-image {
    height: 100%;
    width: auto;
  }
}

@media (max-width: 1050px) {
  .login-image {
    display: none;
  }
    
  .forgot-password-grid {
    text-align: center;
  }
}

@media (max-width: 850px) {
  .login-wrapper {
    width: 100% !important;
  }
}