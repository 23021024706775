.studyLocationsScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.createLocationButtonContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25px;
    right: 25px;
}

.title {
    font-weight: bold !important;
    margin: 20px 0px !important;
}

.subtitle {
    margin-bottom: 20px !important;
    font-weight: normal !important;
}

@media (max-width: 510px) {
    .title {
        font-size: 1.55rem !important;
    }

    .subtitle {
        font-size: 0.8rem !important;
    }
}