.mobilePointItemContainer {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 300px);
}

.mobilePointItem {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    padding: 5px 0px;
}

.mobilePointScreenContainer {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 100vh;
    overflow-y: auto;
}

.mobilePointCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.pointInput {
    width: 30px;
}

@media (max-width: 900px) {
    .mobilePointItemContainer {
        width: 90vw;
    }
}

@media (max-width: 600px) {
    .mobilePointItemContainer {
        width: 85vw;
    }

    .mobilePointItem {
        margin-left: 0px;
    }

    .mobilePointMember {
        font-size: 1rem !important;
    }
}