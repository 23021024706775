.title {
    font-weight: bold !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-align: center;
}

.defaultChangeContainer {
    position: fixed;
    width: 350px;
    right: 0px;
    top: 190px;
}

.defaultChange {
    display: flex;
    align-items: center;
}

.menuAndChangeContainer {
    display: flex;
}

.studyHoursScreen {
    height: calc(100vh - 120px);
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.filterButton {
    background-color: transparent;
    border: none;
}

.tableFilterMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 300px;
}

@media (max-width: 1290px) {
    .defaultChangeContainer {
        position: static;
        align-self: center;
        width: 222px;
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    .defaultChangeContainer {
        margin-bottom: 0px;
    }
}

@media (max-width: 380px) {
    .tableFilterMenu {
        display: none;
    }
}