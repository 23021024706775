.tableContainer {
    height: 80%;
    max-height: 80%;
    width: 90% !important;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24) !important;
    padding: 10px;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
}

.tableHead {
    overflow-x: hidden;
}

.headerCell {
    font-weight: bold !important;
    font-size: 1rem !important;
    white-space: nowrap;
    text-align: center !important;
}

@media (max-width: 1050px) {
    .tableContainer {
        width: 100% !important;
        box-shadow: none !important;
    }
}

@media (max-width: 600px) {
    .tableContainer {
        justify-content: center;
        align-items: center;
    }
}