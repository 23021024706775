.title {
    font-weight: bold !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.organizationSettingsScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.topContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.avatarContainer {
    height: 200px;
    width: 200px;
}

.editProfilePic:hover {
    cursor: pointer;
}

.editOrgContainer {
    width: 50%;
}

.subscriptionContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.activeSubscriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
    padding-bottom: 20px;
}

.currentAndBalanceContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    margin: 10px;
}

.accountBalanceContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
    padding-bottom: 20px;
}

.paymentDetailsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 10px;
}

.plansContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin-bottom: 20px;
    margin-top: 10px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    padding-bottom: 20px;
}

.singlePlanContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 10px;
}

.editAvatarDialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.zoomContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.subscribeButton {
    width: 75px !important;
}

@media (max-width: 1280px) {
    .subscriptionContainer {
        flex-direction: column;
    }
    .activeSubscriptionContainer, .accountBalanceContainer, .plansContainer {
        width: 80%;
    }

    .currentAndBalanceContainer {
        width: 100%;
    }
}

@media (max-width: 612px) {
    .topContainer {
        flex-direction: column;
    }
    .editOrgContainer {
        width: 80%;
    }
    .title {
        font-size: 2rem !important;
    }
    .avatarContainer {
        height: 150px;
        width: 150px;
        margin-bottom: 20px;
    }
}

@media (max-width: 545px) {
    .plansContainer,
    .accountBalanceContainer,
    .activeSubscriptionContainer {
        width: 90%;
    }
}

@media (max-width: 486px) {
    .title {
        font-size: 1.5rem !important;
    }
    .avatarContainer {
        height: 100px;
        width: 100px;
    }
    .singlePlanContainer {
        flex-direction: column;
    }
    .subscribeButton {
        width: 100% !important;
    }
}