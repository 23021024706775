.announcementsScreen {
    height: calc(100vh - 110px);
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.createAnnouncementButtonContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25px;
    right: 25px;
}

.createAnnouncementButton {
    padding: 20px !important;
    border-radius: 50% !important;
}

.title {
    font-weight: bold !important;
    margin-bottom: 30px !important;
}

.tableFilterMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 300px;
}

.filterButton {
    background-color: transparent;
    border: none;
}

@media (max-width: 1050px) {
    .announcementsScreen {
        padding: 0px;
    }

    .title {
        margin-top: 20px;
    }
}
