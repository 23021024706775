.activeButton {
    background-color: #f5f5f5;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding: 2px 4px;
    text-align: center;
    transition: background-color 0.3s;
}

@media (max-width: 1150px) {
    .activeButton {
        display: none;
    }
}

.active {
    background-color: #8BFF6F;
}

.inactive {
    background-color: #FF6D6D;
}